import React from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'

import TermsPages from '../partials/Terms/About'
import { dataTerms } from '../data/terms/terms-boleto-50'

const TermsPage = () => (
  <>
    <GatsbySeo nofollow={true} noindex={true} />
    <TermsPages data={dataTerms} linkCanonical="boleto-50" />
  </>
)
export default TermsPage
